import Text from '@components/atoms/Text';
import Title from '@components/atoms/Title';
import Button from '@components/Button';
import { Column, Columns } from '@components/website/Columns';
import PortableText from '@components/website/PortableText';
import { ModuleAutoStoreBrandCard as ModuleAutoStoreBrandCardType } from '@sanity/sanity.types';
import Image from 'next/image';

import { ModuleComponentType } from '../../../../types/module';
import SectionWrap from '../ModuleSection';
import styles from './ModuleAutoStoreBrandCard.module.scss';

const ModuleAutoStoreBrandCard: ModuleComponentType = ({ data }) => {
  const { header, richDescription, CTA } =
    data as unknown as ModuleAutoStoreBrandCardType;

  return (
    <SectionWrap className={styles.wrap}>
      <Columns>
        <Column start={1} span={4}>
          <Image
            className={styles.logo}
            src="/static/images/autostore-logo.svg"
            width={187}
            height={40}
            alt="AutoStore Logo"
          />
        </Column>
        <Column start={6} span={8}>
          <Title className={styles.header} color="white" variant="h3" as="h2">
            {header}
          </Title>
          {richDescription && (
            <div className={styles.portableWrapper}>
              <div className={styles.portableOverrides}>
                <PortableText body={richDescription} />
              </div>
            </div>
          )}
          {CTA && (
            <Button variant="smallAutoStore" className={styles.cta} {...CTA}>
              {CTA?.label}
            </Button>
          )}
        </Column>
        <Column start={1} span={12} className={styles.brands}>
          <Text variant="b3" color="grey2">
            Trusted by
          </Text>
          <div className={styles.brandsImage}>
            <Image
              src="/static/images/autostore-brands.svg"
              alt="AutoStore Logo"
              fill
            />
          </div>
        </Column>
      </Columns>
    </SectionWrap>
  );
};

export default ModuleAutoStoreBrandCard;
